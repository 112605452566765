import React, {useState, useEffect} from "react";
import ReactModal from 'react-modal';
import { useNavigate, useLocation } from "react-router-dom"; 
import "../../../src/css/style.css"

import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../css/confirmalert.css'

function FeedbackItem(props) {

    let rate = props.data.rate

    const posiveFeedbacks = ["친절해요","준비성이 좋아요","시간 약속을 잘 지켜요", "전문적이에요","서비스가 꼼꼼해요", "소통이 원활해요"]
    const negativeFeedbacks = [ "불친절해요","준비성이 부족해요","시간 약속을 잘 지키지 않아요","전문적이지 않아요","서비스가 불성실해요","소통이 원활하지 않아요"]

    let feedback = " 1"
    if(props.data.feedback > 0)
        feedback = posiveFeedbacks[props.data.feedback-1]
    else
        feedback = negativeFeedbacks[-props.data.feedback-1]

        return (
    <div className="starWrapBox">
        {feedback}
        <div className="starWrap">
        <div className={"star " + ((rate>=1)?"fill":"")}></div>
        <div className={"star " + ((rate>=2)?"fill":"")}></div>
        <div className={"star " + ((rate>=3)?"fill":"")}></div>
        <div className={"star " + ((rate>=4)?"fill":"")}></div>
        <div className={"star " + ((rate>=5)?"fill":"")}></div>
        </div>
    </div>
    )

}

function ShowSupporterInfo() {

    const navigate = useNavigate() 
    const location = useLocation()

    const [supporterData, setSupporterData] = useState({})
    const [feedbackData, setFeedbackData] = useState([{rate:4, feedback:1}, {rate:2, feedback:-4}, {rate:5, feedback:5}])
    const [isReady, setIsReady] = useState(false)

    const supseqnum = location.state.supseqnum

    const [menuOption, setMenuOption] = useState('') 


    const initRead = async() => {
  
    
        let url  = "https://rest.besideme.life/rest/v1/supporters/"+supseqnum 
 
    
        await fetch(url)
        .then(response=>{ 
            
            return response.json();
        })
        .then(data=>{  
          if(data.code == "error")
            {
           //   alert(data.message);
              confirmAlert({
                message: data.message,
                buttons: [{label: '확인'}]
            });
              return;
            }
          setSupporterData(data.result)


          url = "https://rest.besideme.life/rest/v1/supporters/"+supseqnum +"/feedbacklist"
 
        
            fetch(url)
            .then(response=>{ 
                
                return response.json();
            })
            .then(data=>{  
                if(data.code == "error")
                {
                //    alert(data.message);
                    confirmAlert({
                        message: data.message,
                        buttons: [{label: '확인'}]
                    });
                    return;
                }
                setFeedbackData(data.result)
                setIsReady(true)
            })
            .catch(err => {
             //   alert(err.message);
                confirmAlert({
                    message: err.message,
                    buttons: [{label: '확인'}]
                });
            }); 


        })
        .catch(err => {
           // alert(err.message);
            confirmAlert({
                message: err.message,
                buttons: [{label: '확인'}]
            });
        }); 
      }
      useEffect(() => {initRead()},[]);



    function menuOptionToggle() {
        menuOption === 'ac'?setMenuOption(''):setMenuOption('ac')
    }

    function handleBackClick() { 
        navigate(-1)
    }

    if(!isReady) 
        { 
            return (
                <div></div>
            )
        }
        else
        {
            return (

                <>
                    <div className="subpageHeader" style={{
                    }}>
                        <div className="topSection">
                            <div className="back" onClick={handleBackClick}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{zIndex:'999'}}>
                                <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                                <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            {/*
                            <div className="menuOption">
                                <svg onClick={menuOptionToggle} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="6" r="1.5" fill="black"/>
                                    <circle cx="12" cy="12" r="1.5" fill="black"/>
                                    <circle cx="12" cy="18" r="1.5" fill="black"/>
                                </svg>
                                <div className={`menu ${menuOption}`}>
                                    <div className="dropMenu">
                                        <button>수정하기</button>
                                    </div>
                                    <div className="dim" onClick={menuOptionToggle}></div>
                                </div>
                            </div>
                            */}
                        </div>

                        <div className="profileWrap">
                            <div className={'profile ' + (supporterData.isCertified==0?'nobadge':'badge')}  style={{overflow:'visible'}}>
                                <img src={"https://buddypics.s3.ap-northeast-2.amazonaws.com/"+supseqnum+".jpg?${new Date()}"} onError={(e) => e.target.src = 'https://buddypics.s3.ap-northeast-2.amazonaws.com/profile.png'}></img>
                                <svg hidden={supporterData.isCertified == 0} className='checkBadge' width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.6072 11.0791L27.615 10.9551L28.6072 11.0791C28.7178 10.1947 28.2297 9.34386 27.4105 8.99275L18.2878 5.08302C17.7847 4.86741 17.2152 4.86741 16.7121 5.08302L7.58941 8.99275C6.77017 9.34385 6.28214 10.1947 6.39269 11.0791L7.46401 19.6497C7.68416 21.4109 8.56513 23.0227 9.92866 24.159L16.2196 29.4014C16.9613 30.0195 18.0386 30.0195 18.7803 29.4014L25.0713 24.159C26.4348 23.0227 27.3158 21.4109 27.5359 19.6497L28.6072 11.0791Z" fill="#45D88E" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                                <path d="M13.125 17.5L17.0688 21.4437C17.2919 21.6668 17.6633 21.6301 17.8384 21.3675L23.3334 13.125" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                                </svg>
                            </div>
                            {supporterData.userName}
                        </div>
                    </div>
                    <div style={{
                        padding:'0 16px'
                    }}>
                        <div className="profileInfo">
                            <div className="section">
                               <pre style={{whiteSpace: "pre-wrap"}}>{supporterData.supDesc}</pre>
                            </div>
                            <div className="section" style={{
                                paddingBottom:'35px'
                            }}>
                                <h3>제 소개를 할게요</h3>
                                <pre style={{
                                    marginBottom:'35px',
                                    whiteSpace: "pre-wrap"
                                }}>{supporterData.supDescDetail}
                                </pre>

                                <h3>성격 및 성향</h3>
                                <div className="radio2" style={{
                                    marginBottom:'35px'
                                }}>
                                    <div className="item black" hidden={(supporterData.supStyle & 1) == 0}>내성적인</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 1) > 0}>외향적인</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 2) == 0}>친절한</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 2) > 0}>단호한</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 4) == 0}>꼼꼼한</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 4) > 0}>대범한</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 8) == 0}>차분한</div>
                                    <div className="item black" hidden={(supporterData.supStyle & 8) > 0}>활기찬</div>
                                </div>

                                <h3>차량 보유 여부</h3>
                                <div className="radio2">
                                  {/*}  <div className={"item black " + ((supporterData.supHaveCar == 1)?'fill':'')}>차량{(supporterData.supHaveCar == 1)?"":"미"}보유</div> */}
                                    <div className={"item black"}>차량{(supporterData.supHaveCar == 1)?"":"미"}보유</div>
                                </div>
                            </div>
                            <div className="section" style={{
                                paddingBottom:'45px',
                                border:'0'
                            }}>
                                <h3 style={{
                                    marginBottom:'21px'
                                }}>이용 후기</h3>
                                <div>
                                {
                                feedbackData.map((feedback, index) => ( 

                                <FeedbackItem data={feedback} key={index}/>
                                    ))
                                } 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="goldBox">
                        <button className="cta" onClick={handleBackClick}>확인</button>
                    </div>
                </>
            )
    }
}


export default ShowSupporterInfo