import React, { useState, useContext, useEffect, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App'; 
 
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../css/confirmalert.css'

export default function AccountManagement() {

  const navigate = useNavigate(); 
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext)
  

  const location = useLocation()
  const userData = location.state.userData


  const [numValue , setNumValue] = useState(userData.accNum)
  const [nameValue , setNameValue] = useState(userData.accName)

// TODO
//  const [accountValidated, setAcountValidated] = useState(false)
  const [accountValidated, setAcountValidated] = useState(true)

  const [cNum1 , setCNum1] = useState(userData.cNum1)
  const [cNum2 , setCNum2] = useState(userData.cNum2)


  const [isAgreed, setIsAgreed] = useState(false)

  let bank = userData.accBank
  if(bank == "")
    bank = "KB국민은행"
 
  function handleCheckChange() {
    setIsAgreed(!isAgreed)
  }

  function handleBackClick() {
    navigate(-1)
  }
   
  async function handleSaveClick() { 

    if(!isAgreed) 
    {
      confirmAlert({
        message: '동의해 주시기 바랍니다.',
        buttons: [{label: '확인'}]
    });
      return;
    }
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum 
    let params = {accBank : bank, accNum : numValue.replace(/'/g,"''"), accName: nameValue.replace(/'/g,"''"), cNum1: cNum1, cNum2: cNum2}

    await fetch(url, {     
    method : "PUT",         
    body: JSON.stringify(params)   
    })
    .then(response=>{  
    return response.json();
    })
    .then(data=>{   
      if(data.code == "error")
        {
      //    alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
 
  //    alert("저장되었습니다.")
      confirmAlert({
        message: '저장되었습니다.',
        buttons: [{label: '확인'}]
    });
      handleBackClick()
      
    })
    .catch(err => {
    //    alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 

  }


  const changeBankSelect = (event) =>{
    bank = event.target.value
    setAcountValidated(false)
};

async function handleConfirmAccount() {


  //TODO : 본인 계좌 확인
  let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum 
  let params = {accBank : bank, accNum : numValue.replace(/'/g,"''"), accName: nameValue.replace(/'/g,"''"), cNum1: cNum1, cNum2: cNum2}

  await fetch(url, {     
  method : "POST",         
  body: JSON.stringify(params)   
  })
  .then(response=>{  
  return response.json();
  })
  .then(data=>{   
    if(data.code == "error")
      { 
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
      });
        return;
      }
 
      confirmAlert({
        message: '본인 계좌 확인 되었습니다.',
        buttons: [{label: '확인'}]
      });
      setAcountValidated(true)
    
  })
  .catch(err => { 
      confirmAlert({
        message: err.message,
        buttons: [{label: '확인'}]
    });
  }); 

  confirmAlert({
    message: '본인 계좌 확인 되었습니다.',
    buttons: [{label: '확인'}]
  });
  setAcountValidated(true)
}

return (
  <div className='serviceSelect'>
  <div className='inner32'>
    <div className='pageBack' onClick={handleBackClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
      </svg>
    </div>
    <h1 className='pageTit' style={{
      marginBottom:'43px'
    }}>정산 계좌 관리 </h1>
  </div>

  <div className='inner'>
    <div className='inputWrap'>
      <div className='label'>은행</div>
      <select className='input borderSelect' name="selectBank" defaultValue={userData.accBank} onChange={changeBankSelect}>
        <option key="1" value="KB국민은행">KB국민은행</option>
        <option key="2"  value="신한은행">신한은행</option>
        <option key="3"  value="KEB하나은행"> KEB하나은행</option>
        <option key="4"  value="우리은행">우리은행</option>
        <option key="5"  value="IBK기업은행">IBK기업은행</option>
        <option key="6"  value="SC제일은행">SC제일은행</option>
        <option key="7"  value="NH농협은행">NH농협은행</option>
        <option key="8"  value="수협은행">수협은행</option>
        <option key="9"  value="토스뱅크">토스뱅크</option>
        <option key="10"  value="케이뱅크">케이뱅크</option>
        <option key="11"  value="카카오뱅크">카카오뱅크</option>
        <option key="12"  value="토스뱅크">토스뱅크</option>
        <option key="13"  value="새마을금고">새마을금고</option>
        <option key="14"  value="우체국">우체국</option>
        <option key="15"  value="신용협동조합">신용협동조합</option>
        <option key="16"  value="경남은행">경남은행</option>
        <option key="17"  value="부산은행">부산은행</option>
        <option key="18"  value="광주은행">광주은행</option>
        <option key="19"  value="제주은행">제주은행</option>
        <option key="20"  value="전북은행">전북은행</option>
      </select>
    </div>
    <div className='inputWrap'>
      <div className='label'>계좌번호</div>
      <input className='input' value={numValue} onChange={(e) => {
          setNumValue(e.target.value);
          setAcountValidated(false)
        }}></input>
    </div>
    <div className='inputWrap'>
      <div className='label'>예금주</div>
      <input className='input' value={nameValue} onChange={(e) => {
          setNameValue(e.target.value);
          setAcountValidated(false)
        }}></input>
    </div>
    <div className='inputWrap'>
      <div className='label'>주민등록번호</div>
      <div className='multi'>
        <input className='input' maxLength="6" value={cNum1} onChange={(e) => {
            setCNum1(e.target.value);
            setAcountValidated(false)
          }}></input>
          -
        <input className='input' maxLength="7" value={cNum2} onChange={(e) => {
            setCNum2(e.target.value);
            setAcountValidated(false)
          }}></input>
        </div>
    </div>
{
//TODO
}
    <div className='cta' hidden={true} style={{width:'30%', marginTop:'20px'}}><button disabled={accountValidated} className={'cta ' + ((accountValidated)?'disabled':'')} onClick={handleConfirmAccount}>본인 계좌 확인</button></div>

    <div style={{
      marginTop:'42px',
      fontSize:'14px',
      lineHeight:'1.2'
    }}>
      
    <div className='inputWrap'>
      <div className='label'>&lt;꼭! 확인해 주세요&gt;</div>  
    </div>
  
  <div style={{fontSize:"14px"}}>
1. 비사이드미는 서포터 회원 명의의 개인 계좌로만 정산금을 지급합니다. 사업자 계좌에 대한 정산은 불가합니다. <br/><br/>

2. 정산지급 금액은, 도움요청 회원의 결제 비용(쿠폰, 적립금 등의 결제금액 제외)을 기준으로 비사이드미 수수료, 카드 수수료, 원천세 등의 세금을 공제한 후 지급됩니다.<br/><br/>

3. 정산금은 매월 1주-2주차 서포트 진행 건은 당월 3주차 금요일에, 매월 3주차~월말 서포트 진행 건은 익월 1주차 금요일에 지급됩니다(금요일이 법정 공휴일인 경우 공휴일 직후 첫 번째 영업일에 지급)<br/><br/>

4. 원천세 신고를 위한 귀하의 주민등록번호의 수집, 제공이 이루어집니다. 잘못 기재된 주민등록번호로 인하여 신고에 문제가 발생한 경우 이에 대한 책임은 정보를 입력한 회원에게 있습니다.<br/><br/>
  </div>
  
        <label className='agreecheckbox'>
                        <input type='checkbox' checked={isAgreed} onChange={handleCheckChange}></input>
                        <svg className='checkIcon' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.7682 6.64018C14.1218 6.21591 14.0645 5.58534 13.6402 5.23178C13.2159 4.87821 12.5853 4.93554 12.2318 5.35982L8.63383 9.67736C8.28711 10.0934 8.09219 10.3238 7.93658 10.4653L7.93063 10.4707L7.92422 10.4659C7.75643 10.339 7.54146 10.1272 7.1585 9.74428L5.70711 8.29289C5.31658 7.90237 4.68342 7.90237 4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L5.74428 11.1585L5.78511 11.1993L5.78512 11.1993C6.11161 11.526 6.4257 11.8402 6.71794 12.0611C7.0453 12.3087 7.47402 12.5415 8.02343 12.5165C8.57284 12.4916 8.97872 12.221 9.28233 11.9448C9.55337 11.6983 9.83772 11.3569 10.1333 11.0021L10.1333 11.0021L10.1703 10.9577L13.7682 6.64018Z" fill="#BAB288"/></svg>
                        (필수) 유의 사항을 확인했으며, 모두 동의합니다.
        </label>
        
      </div> 
 
    

    <div className='ctaWrap bottomCta'><button disabled={!(isAgreed & accountValidated)} className={'cta ' + ((!(isAgreed & accountValidated))?'disabled':'')} onClick={handleSaveClick}>확인</button></div>
  </div>
</div>
  )
};