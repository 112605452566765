import React, {useState, useEffect, useContext} from 'react';
import BottomNavigation from './BottomNavigation';
import { Routes, Route, useNavigate } from "react-router-dom"; 
import AddressList from './componentsMypage/AddressList';
import AddressAdd from './componentsMypage/AddressAdd';
import {AppContext} from '../App';
import NeedLogin from './NeedLogin';
import SupporterInfo from './componentsMypage/SupporterInfo';
import NoticeList from './componentsMypage/NoticeList';
import NoticeMain from './componentsMypage/NoticeMain';
import AccountManagement from './componentsMypage/AccountManagement';
import CertifiedSupporter from './componentsMypage/CertifiedSupporter';
import CertifiedSupporterBenefit from './componentsMypage/CertifiedSupporterBenefit';  

import { confirmAlert } from 'react-confirm-alert'; // Import 
import '../css/confirmalert.css'
import '../scss/mypage.scss'

export function MyInfo({pageData, addrIndex}) {

  const navigate = useNavigate(); 
    
  function handleAddressList() {
    navigate("/Mypage/AddressList", { replace: false, state : {addrIndex: (addrIndex)}})

  }

  return (
    <div className='inner top'>
      <div className='flex pr15'><b className='key'>이름</b> <b>{pageData.userName}</b> </div>
      <div className='flex pr15'><b className='key'>전화번호</b>  {phoneNumberAutoFormat(pageData.userPhoneNum)}</div>
      <div className='flex'>
        <b className='key'>방문장소</b>
        <div className='addr' onClick={handleAddressList}>
          <div>{pageData.addr}<br/>{pageData.addrDetail} </div>
          <font color="blue">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </font>
        </div>
      </div>
    </div>
  ) 
};

function phoneNumberAutoFormat (phoneNumber) {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

export function MySupporterInfo({pageData, addrIndex}) {

  const navigate = useNavigate(); 
    
  function handleAddressList() {
    navigate("/Mypage/AddressList", { replace: false, state : {addrIndex: (addrIndex)}})

  }

  return (
    <div className='inner top'>
      <div className='flex pr15'><b className='key'>이름</b> <b>{pageData.userName}</b> </div>
      <div className='flex pr15'><b className='key'>전화번호</b>  {phoneNumberAutoFormat(pageData.userPhoneNum)}</div>

    </div>
  ) 
};

function MypageMain() {
 
  const [addrIndex, setAddrIndex] = useState(-1)


  const [pageData, setPageData] = useState({userName : "", userPhoneNum : "", addrName : "", addr : "", addrDetail : "", isCertified : 0})
 
  const {globalUserData, setGlobalUserData, modeSupporter, setModeSupporter } = useContext(AppContext); 

  const navigate = useNavigate();

  const initRead = async() => {

    if(globalUserData.userSeqNum == 0)
      return
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum;


    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
//        alert(data.message);
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
      });        
        return;
      }

      setAddrIndex(data.result.userAddrIndex)

      setPageData(data.result)

    })
    .catch(err => {
//        alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
  }
  useEffect(() => {initRead()},[globalUserData.userSeqNum]);

  function handleBoard() {
    navigate("./NoticeList", { replace: false})
  }
  function handleAccountManagement() {
    navigate("./AccountManagement", { replace: false, state: {userData: pageData}})
  }
  function handleCertificate() {
    navigate("./CertifiedSupporter", { replace: false})
  }
  function handleInquiry() {
    window.location.href = "http://pf.kakao.com/_MuxlMn/chat"
  }

   async function doLogout() {
 

    let url = "https://rest.besideme.life/rest/v1/session/"+globalUserData.userSeqNum+"/"+globalUserData.secretKey;

    await fetch(url, {
        method : "DELETE"    
      })
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      { 
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
        });        
        return;
      }

    })
    .catch(err => { 
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
    

    setGlobalUserData({userSeqNum:0, userUniqueID:"", userPhoneNum:"", userName:"", usageIndex:0, secretKey:""})
    if(window.androidApp)
     window.androidApp.clearPref();
  }

  function handleLogout() {

    confirmAlert({ 
      message: '로그아웃 하시겠습니까?',
      buttons: [
        { label: '아니오' },
        {
          label: '예',
          onClick: () => doLogout()
        } 
      ]
    });


//    if(window.confirm("로그아웃 하시겠습니까?") == true) {
//      setGlobalUserData({userSeqNum:0, userUniqueID:"", userPhoneNum:"", userName:"", usageIndex:0})
//      if(window.androidApp)
//       window.androidApp.clearPref();
//    }
  }

  async function cleanUp() {

    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum + "/unroll";

    await fetch(url, {
      method : "DELETE"    
    })
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
//        alert(data.message);
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
      });        
        return;
      }

      setAddrIndex(data.result.userAddrIndex)

      setPageData(data.result)

    })
    .catch(err => {
//        alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 

    doLogout()
    navigate("/", { replace: true}) 
  }

  async function doUnroll() {
 
    
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum + "/unroll";

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 

      if(data.code == "UNROLL_COMFIRM")
      {
        confirmAlert({ 
          message: data.message,
          buttons: [
            { label: '취소' },
            {
              label: '확인',
              onClick: () => cleanUp()
            } 
          ]
        });
      }
      else 
      {
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
        });       
      }

    })
    .catch(err => { 
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 


  }



  function handleUnretgister() {

    doUnroll()
    /*
    confirmAlert({ 
      message: '회원 탈퇴 하시겠습니까? 모든 정보가 삭제됩니다.',
      buttons: [
        {
          label: '예',
          onClick: () => doUnroll()
        },
        { label: '아니오' }
      ]
    });
    */


//    if(window.confirm("회원 탈퇴 하시겠습니까? 모든 정보가 삭제됩니다.") == true) {
//      setGlobalUserData({userSeqNum:0, userUniqueID:"", userPhoneNum:"", userName:"", usageIndex:0})
//      navigate("/", { replace: true})    
//    }
  }

  function handleSupporterInfo() {

    navigate("./SupporterInfo", { replace: false})
  }


  if(globalUserData.userSeqNum > 0) {
    if(modeSupporter)
      return (
     
        <div className='myPage'>
          <h2 style={{
            marginBottom:'0'
          }}>내 정보</h2>
          
          <div className={'myPageProfile ' + (globalUserData.isCertified==0?'pnobadge':'pbadge')}>
            {/* <img></img> */}
            <img src={"https://buddypics.s3.ap-northeast-2.amazonaws.com/"+globalUserData.userSeqNum+".jpg?${new Date()}"} onError={(e) => e.target.src = 'https://buddypics.s3.ap-northeast-2.amazonaws.com/profile.png'}></img>
            <svg hidden={pageData.isCertified == 0} className='profileBadge' width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.6073 11.0788L27.615 10.9547L28.6073 11.0788C28.7178 10.1944 28.2298 9.34352 27.4105 8.99242L18.2878 5.08269C17.7847 4.86707 17.2152 4.86707 16.7122 5.08269L7.58944 8.99242C6.7702 9.34352 6.28217 10.1944 6.39272 11.0788L7.46404 19.6493C7.68419 21.4105 8.56516 23.0224 9.92869 24.1586L16.2196 29.4011C16.9613 30.0192 18.0387 30.0191 18.7804 29.4011L25.0713 24.1586C26.4348 23.0224 27.3158 21.4105 27.5359 19.6493L28.6073 11.0788Z" fill="#45D88E" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
              <path d="M13.125 17.5L17.0687 21.4437C17.2919 21.6668 17.6633 21.6301 17.8383 21.3675L23.3333 13.125" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
            </svg>

          </div>
          <MySupporterInfo pageData = {pageData} addrIndex={addrIndex}/>

          <div className='rowLineWrap'>
          <div className='rowLine' onClick={handleSupporterInfo}>
            서포터 정보 관리 
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>  
          <div className='rowLine' onClick={handleAccountManagement}>
            정산 계좌 관리
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
          <div className='rowLine' onClick={handleCertificate}>
            공식 서포터 인증하기
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div> 
        </div>
 

        <div className="rowLineWrap">
          <div className='rowLine' onClick={handleBoard}>
            공지사항
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
          <div className='rowLine' onClick={handleInquiry}>
            1:1 문의하기
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
        </div>
        <div className="rowLineWrap">
          <div className='rowLine' onClick={handleLogout}>로그아웃</div>
          <div className='rowLine' onClick={handleUnretgister}>회원탈퇴</div>
        </div>

          <BottomNavigation/>
        </div>
      )
    else
      return (
      <div className='myPage'>
        <h2>내 정보</h2>
        <MyInfo pageData = {pageData} addrIndex={addrIndex}/>

{/*
        <div className="rowLineWrap">
          <div className='rowLine'>
            결제수단 관리
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
        </div>
*/}
        <div className="rowLineWrap">
          <div className='rowLine' onClick={handleBoard}>
            공지사항
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
          <div className='rowLine' onClick={handleInquiry}>
            1:1 문의하기
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
        </div>
        <div className="rowLineWrap">
          <div className='rowLine' onClick={handleLogout}>로그아웃</div>
          <div className='rowLine' onClick={handleUnretgister}>회원탈퇴</div>
        </div>

        <BottomNavigation/>
      </div>
    )
  }
  else {
    return (
      <div>
      <NeedLogin showBack="false"/>
      <BottomNavigation/>
      </div>
    )
  }
  };


export default function Mypage() {

  return (
      <div>
        <Routes>
          <Route path="*" element={<MypageMain />} />
          <Route path="AddressList" element={<AddressList />} />
          <Route path="AddressAdd" element={<AddressAdd />} />
          <Route path="SupporterInfo" element={<SupporterInfo />} />
          <Route path="NoticeList" element={<NoticeList />} />
          <Route path="NoticeMain" element={<NoticeMain />} />
          <Route path="AccountManagement" element={<AccountManagement />} />
          <Route path="CertifiedSupporter" element={<CertifiedSupporter />} />
          <Route path="CertifiedSupporterBenefit" element={<CertifiedSupporterBenefit />} />
        </Routes>
  
      </div>
    )
  };

    