import React, {useState, createContext} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";  
import Home from './components/Home';
import Mypage from './components/Mypage';
import Usage from './components/Usage';  
  
export const AppContext = createContext()

export default function App() {
  const [globalUserData, setGlobalUserData] = useState({userSeqNum:0, userUniqueID:"", userPhoneNum:"", userName:"", usageIndex:0, secretKey:""}) 
 
  const [modeSupporter, setModeSupporter] = useState(false) 
  const [showIntro, setShowIntro] = useState(true)

  return ( 
      <AppContext.Provider value={{globalUserData, setGlobalUserData, modeSupporter, setModeSupporter, showIntro, setShowIntro}}>

      <BrowserRouter>
      <div style={{}}>
        <Routes> 
          <Route path="*" element={<Home />} />
          <Route path="/Usage/*" element={<Usage />} />
          <Route path="/Mypage/*" element={<Mypage />} /> 

        </Routes>
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
}
 
