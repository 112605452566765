import React, { useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App';
 
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../css/confirmalert.css'

function AddressInitItem(props) {
  const navigate = useNavigate(); 
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const handleRadioChange = (seqnum) => {  
    props.callback(seqnum) 
  }

  const handleModify = (seqnum) => { 
   
    navigate("/Mypage/AddressAdd", { replace: false, state : {isAdd: 0, addrIndex: (props.addrIndex), addrSeqNum: (props.address.addrSeqNum), addrName: (props.address.addrName), addr: (props.address.addr), addrDetail: (props.address.addrDetail)}})
  } 
  return (
    <label className='radioCard'>
      <div className='icon'></div>
    <input  checked={(props.addrIndex == props.address.addrSeqNum)?true:false} type="radio" name="radio" onChange={() => handleRadioChange(props.address.addrSeqNum)}></input>
    <div className='tit'>{props.address.addrName}</div>
      <div className='command'>
        <span onClick={() => handleModify(props.address.addrSeqNum)}>수정</span>ㅣ
        <span onClick={() => props.deletecallback(props.address.addrSeqNum)}>삭제</span>
      </div>
      <div className='addr'>
        {props.address.addr}<br/>
        {props.address.addrDetail}
      </div>
    </label>
  ) 

};


function AddressItem(props) {
  const navigate = useNavigate(); 
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const handleRadioChange = (seqnum) => {  
    props.callback(seqnum) 
  }

  const handleModify = (seqnum) => { 
   
    navigate("/Mypage/AddressAdd", { replace: false, state : {isAdd: 0, addrIndex: (props.addrIndex), addrSeqNum: (props.address.addrSeqNum), addrName: (props.address.addrName), addr: (props.address.addr), addrDetail: (props.address.addrDetail)}})
  } 
  return (
    <label className='radioCard'>
      <div className='icon'></div>
      <input  checked={(props.selectedRadioSeqNum == props.address.addrSeqNum)?true:false} type="radio" name="radio" onChange={() => handleRadioChange(props.address.addrSeqNum)}></input>
      <div className='tit'>{props.address.addrName}</div>
      <div className="command">
        <span onClick={() => handleModify(props.address.addrSeqNum)}>수정</span>ㅣ
        <span onClick={() => props.deletecallback(props.address.addrSeqNum)}>삭제</span>
      </div>
      <div className="addr">
        {props.address.addr}<br/>
        {props.address.addrDetail}
      </div>
    </label>
  ) 

};
 

export default function AddressList() {

  const navigate = useNavigate();  
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 
  
  const location = useLocation();	
  let addrIndex = location.state.addrIndex;	


  const [selectedRadioSeqNum, setSelectedRadioSeqNum] = useState(0)
  const [showInit, setShowInit] = useState(true)
  const [addressList, setAddressList] = useState([])

  const initRead = async() => {
  
    addrIndex = globalUserData.userAddrIndex
    setSelectedRadioSeqNum(globalUserData.userAddrIndex) 
 
    if(globalUserData.userSeqNum == 0)
      return
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/addresslist";

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
        {
      //    alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        } 
        setAddressList(data.result)
   
    })
    .catch(err => {
    //    alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
  }
  useEffect(() => {initRead()},[globalUserData.userSeqNum, globalUserData.userAddrIndex]);

  function handleSelectedRadioSeqNumCallback(addrSeqNum) {
    setSelectedRadioSeqNum(addrSeqNum) 
    setShowInit(false)
  }

  async function doDelete(addrSeqNum) {
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/addresslist/"+addrSeqNum;
    let params = {}

    await fetch(url, {
      method : "DELETE",       
      body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{    
      if(data.code == "error")
        {
      //    alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
      initRead()
    })
    .catch(err => {
     //   alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
  }

  async function handleDeleteCallback(addrSeqNum) { 
    if(addrIndex == addrSeqNum)
    {
   //   alert("현재 선택중인 장소는 삭제할 수 없습니다.")
      confirmAlert({
        message: '현재 선택중인 장소는 삭제할 수 없습니다.',
        buttons: [{label: '확인'}]
    });
      return;
    }

    confirmAlert({ 
      message: '삭제 하시겠습니까?',
      buttons: [
        { label: '아니오' },
        {
          label: '예',
          onClick: () => doDelete(addrSeqNum)
        } 
      ]
    });

//    if(window.confirm("삭제 하시겠습니까?") == true) 
//    {
//      doDelete()
//    }

  }


  function handleAddClick() {
    navigate("/Mypage/AddressAdd", { replace: false, state : {isAdd: 1, addrIndex: (addrIndex)}})
  }
  function handleBackClick() {
//    navigate("/Mypage", { replace: true}) 
    navigate(-1)
  }
  async function handleSelectClick() 
  {
    if(selectedRadioSeqNum == -1)
    {
//      alert("선택할 주소를 지정해 주세요.")
      confirmAlert({
        message: '선택할 주소를 지정해 주세요.',
        buttons: [{label: '확인'}]
    });
      return
    } 
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum;
    let params = {userAddrIndex : (selectedRadioSeqNum)}

    await fetch(url, {     
      method : "PUT",         
      body: JSON.stringify(params)   
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
        {
      //    alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
//      navigate("/Mypage", { replace: true})
      navigate(-1)
    })
    .catch(err => {
    //    alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 

    
  }



  if(showInit) 
    {
      return (
        <div className='addressList'>

        <div className='subpageHeader'>
          <div onClick={handleBackClick} className='back'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
          <h2>방문 장소 관리</h2>
        </div>
        <div className='inner'>
          <div className='selectPlace'>
            {
              addressList.map((addr, index) => (
                <AddressInitItem deletecallback={handleDeleteCallback} callback={handleSelectedRadioSeqNumCallback} selectedRadioSeqNum={selectedRadioSeqNum} address={addr} addrIndex={addrIndex} key={index}></AddressInitItem>
                  ))
            }
            <button className='addPlace' onClick={handleAddClick}>+ 방문장소 추가</button>
          </div>
          <div className="ctaWrap bottomCta"><button className='cta' onClick={handleSelectClick}>+ 방문장소 선택</button></div>
        </div>
    </div>
        )
    }
    else
    {
      return (
        <div className='addressList'>

        <div className='subpageHeader'>
          <div onClick={handleBackClick} className='back'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div>
          <h2>방문 장소 관리</h2>
        </div>
        <div className='inner'>
          <div className='selectPlace'>
            {
              addressList.map((addr, index) => (
                <AddressItem deletecallback={handleDeleteCallback} callback={handleSelectedRadioSeqNumCallback} selectedRadioSeqNum={selectedRadioSeqNum} address={addr} addrIndex={addrIndex} key={index}></AddressItem>
                  ))
            }
            <button className='addPlace' onClick={handleAddClick}>+ 방문장소 추가</button>
          </div>
          <div className="ctaWrap bottomCta"><button className='cta' onClick={handleSelectClick}>+ 방문장소 선택</button></div>
        </div>
        </div>
      )
    } 

};