import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from "react-router-dom";  
import DaumPostcode from 'react-daum-postcode';
import {AppContext} from '../../App';
import ReactModal from 'react-modal';

import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../css/confirmalert.css'

export default function AddressAdd() {

  const navigate = useNavigate() 
  const [addressTyped, setAddressTyped] = useState("")
  const [addressDetailTyped, setAddressDetailTyped] = useState("")
  const [nameTyped, setNameTyped] = useState("")

  const [btnEnable, setBtnEnable] = useState(true)

  const [modalState, setModalState] = useState(false)
  const [headTitle, setHeadTitle] = useState("방문 장소")
  
  const location = useLocation();	
  let addrIndex = location.state.addrIndex;	
  const isAdd = location.state.isAdd;


  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  useEffect(() => {

    if(isAdd == 1)
    { 
      setHeadTitle("방문 장소 추가 (현재는 서울 지역만 가능합니다.)")
    }
    else
    {
      setHeadTitle("방문 장소 수정 (현재는 서울 지역만 가능합니다.)")
      setNameTyped(location.state.addrName);
      setAddressTyped(location.state.addr);
      setAddressDetailTyped(location.state.addrDetail)
    }
  }, [])

  function handleBackClick() {
//    navigate("/Mypage/AddressList", { replace: true, state : {addrIndex: (addrIndex)}}) 
    navigate(-1) 
  }

  async function handleSave() {
    if(nameTyped.length == 0) {
  //    alert("이름을 입력해 주세요!")
      confirmAlert({
        message: '이름을 입력해 주세요!',
        buttons: [{label: '확인'}]
    });
      return
    }
    if(addressTyped.length == 0) {
  //    alert("주소를 입력해 주세요!")
      confirmAlert({
        message: '주소를 입력해 주세요!',
        buttons: [{label: '확인'}]
    });
      return
    }

    let url = ""
    if(isAdd == 1)
    {
  
        url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/addresslist";
        let params = {addrName: (nameTyped.replace(/'/g,"''")), addr: (addressTyped.replace(/'/g,"''")), addrDetail: (addressDetailTyped.replace(/'/g,"''")), addrIndex: (addrIndex)}

        await fetch(url, {
          method : "POST",         
          body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
        })
        .then(response=>{  
          return response.json();
        })
        .then(data=>{  
          if(data.code == "error")
            {
          //    alert(data.message);
              confirmAlert({
                message: data.message,
                buttons: [{label: '확인'}]
            });
              return;
            }
          if(addrIndex == -1)
          {
            addrIndex = data.result.addrIndex

            let globalUserData_ = {...globalUserData}
            globalUserData_.userAddrIndex = addrIndex
            setGlobalUserData(globalUserData_)  
          }
//          handleBackClick()  
          setBtnEnable(false)
//          alert("추가되었습니다.")
        })
        .catch(err => {
        //    alert(err.message);

            confirmAlert({
              message: err.message,
              buttons: [{label: '확인'}]
          });
        }); 

        handleBackClick()  
    }
    else 
    {
      
      url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/addresslist/"+location.state.addrSeqNum;
      let params = {addrName: (nameTyped.replace(/'/g,"''")), addr: (addressTyped.replace(/'/g,"''")), addrDetail: (addressDetailTyped.replace(/'/g,"''"))}

      await fetch(url, {
        method : "PUT",       
        body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
      })
      .then(response=>{  
        return response.json();
      })
      .then(data=>{  
        if(data.code == "error")
          {
        //    alert(data.message);
            confirmAlert({
              message: data.message,
              buttons: [{label: '확인'}]
          });
            return;
          }

        setBtnEnable(false)
      })
      .catch(err => {
      //    alert(err.message);
          confirmAlert({
            message: err.message,
            buttons: [{label: '확인'}]
        });
      }); 
    }

    handleBackClick()  


  }
 
  function handleSearchClick() {
    setModalState(true)
  }
  
  function handleSearchclose() {
    setModalState(false)
  }
  const postCodeStyle = {
    height: '700px',
    
  };

  const onCompletePost = data => {
    setModalState(false)
    setAddressTyped(data.address) 
    handleBackClick()
  };
return (
    <div className='addressList'>

      <div className='subpageHeader'>
        <div className='back' onClick={handleBackClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </div>
        <h2>{headTitle}</h2>
      </div>
      <div className='inner mt55'>
        <div className='inputWrap'>
          <div className='label'>이름</div>
          <input className='input' value={nameTyped} onChange={(event)=>setNameTyped(event.target.value)} type="text" id="name" placeholder="ex. 우리집"></input>
        </div>
        <div className='inputWrap'>
          <div className='label'>주소</div>
          <label className='address'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.398 19.804L12.8585 20.6917L12.8585 20.6917L12.398 19.804ZM11.602 19.804L11.1415 20.6917L11.1415 20.6917L11.602 19.804ZM18 11C18 13.1458 16.9079 14.9159 15.545 16.2906C14.183 17.6644 12.6342 18.555 11.9376 18.9163L12.8585 20.6917C13.6448 20.2838 15.397 19.2805 16.9653 17.6987C18.5326 16.1178 20 13.8706 20 11H18ZM12 5C15.3137 5 18 7.68629 18 11H20C20 6.58172 16.4183 3 12 3V5ZM6 11C6 7.68629 8.68629 5 12 5V3C7.58172 3 4 6.58172 4 11H6ZM12.0624 18.9163C11.3658 18.555 9.81702 17.6644 8.45503 16.2906C7.09211 14.9159 6 13.1458 6 11H4C4 13.8706 5.46741 16.1178 7.03474 17.6987C8.60299 19.2805 10.3552 20.2838 11.1415 20.6917L12.0624 18.9163ZM11.9376 18.9163C11.9514 18.9091 11.9733 18.9023 12 18.9023C12.0267 18.9023 12.0486 18.9091 12.0624 18.9163L11.1415 20.6917C11.6831 20.9726 12.3169 20.9726 12.8585 20.6917L11.9376 18.9163ZM14 11C14 12.1046 13.1046 13 12 13V15C14.2091 15 16 13.2091 16 11H14ZM12 9C13.1046 9 14 9.89543 14 11H16C16 8.79086 14.2091 7 12 7V9ZM10 11C10 9.89543 10.8954 9 12 9V7C9.79086 7 8 8.79086 8 11H10ZM12 13C10.8954 13 10 12.1046 10 11H8C8 13.2091 9.79086 15 12 15V13Z" fill="#33363F"/>
            </svg>
            <input className='input gold' placeholder='건물, 지번 또는 도로명 검색' readOnly value={addressTyped} onClick={handleSearchClick} onChange={(event)=>setAddressTyped(event.target.value)}id="address" ></input>
            <button className='hiddenBtn' onClick={handleSearchClick}>검색</button>
          </label>
          <input className='input mt8 gold' value={addressDetailTyped} onChange={(event)=>setAddressDetailTyped(event.target.value)} type="text" id="addressDetail" placeholder="상세 주소"></input>
        </div>
      </div>

      <ReactModal isOpen={modalState} onRequestClose={handleSearchclose} ariaHideApp={false}
                  style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                      position: 'absolute',
                      top: '200px',
                      left: '40px',
                      right: '40px',
                      bottom: '40px',
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px'
                    }
                  }}
      >
      <div className='searchAddrModal'>
          <div className='pannel'>
            <div className="inner">
              <div className='back' onClick={handleSearchclose} style={{display: "inline-block"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
              </div> <h5 style={{display: "inline-block", paddingLeft:"50px"}}>주소 검색 (현재는 서울 지역만 가능합니다.)</h5>
              <DaumPostcode
                style={postCodeStyle}
                onComplete={onCompletePost}
                className='mapAPI'
              ></DaumPostcode>
            </div>
          </div>
        </div>
        </ReactModal>


      <div className="ctaWrap bottomCta">
        <button className={'cta ' + ((!btnEnable)?'disabled':'')} disabled={!btnEnable} onClick={handleSave}>완료</button>
      </div>
      
    </div>
  )
};
 