import React, {useState, useRef, useContext, useEffect} from 'react';
import { AppContext } from '../App';  
import { useNavigate, useLocation } from "react-router-dom"; 
import ReactModal from 'react-modal'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../css/confirmalert.css'

const NeedLogin = (props) => {

    const [showAgreeDialog, setShowAgreeDiaglog] = useState(false)
    const [enableRequestButton, setEnableRequestButton] = useState(true)
    const [enableConfirmButton, setEnableConfirmButton] = useState(false)

    const [requiredAgree1, setRequiredAgree1] = useState(false)
    const [requiredAgree2, setRequiredAgree2] = useState(false)
    const [requiredAgree3, setRequiredAgree3] = useState(false) 
    const [selectableAgree1, setSelectableAgree1] = useState(false)
    const [selectableAgree2, setSelectableAgree2] = useState(false)
    const [selectableAgree3, setSelectableAgree3] = useState(false)

    const [selectedCarrier, setSelectedCarrier] = useState(1)

    const [minutes, setMinutes] = useState(10);
    const [seconds, setSeconds] = useState(0);

    const [kakakoLogin, setKakaoLogin] = useState(false)
   
/*
      useEffect(() => {
     
        const eventFromAndroid = async (event) => {

            let agreeFlag = selectableAgree1 + selectableAgree2*2 + selectableAgree3*4

            let url = "https://rest.besideme.life/kakao_cert.php";
            let params = {ci : event.detail.ci, phone : event.detail.phone, name : event.detail.name, agreeFlag : agreeFlag}

            await fetch(url, {
            method : "POST",       
            body: JSON.stringify(params)   
            })
            .then(response=>{  
            return response.json();
            })
            .then(data=>{  

                if(data.resultCode != "2000")
                { 
                    confirmAlert({
                        message: data.resultMsg,
                        buttons: [{label: '확인'}]
                    }); 
                    return;
                } 
                setGlobalUserData(data.result[0])
                
                if(window.androidApp)
                    window.androidApp.setPref(data.result[0].userSeqNum, data.secretKey); 
            
                return;
            })
            .catch(err => { 
                confirmAlert({
                    message: err.message,
                    buttons: [{label: '확인'}]
                });
            }); 

        }

        window.addEventListener('javascriptFunctionKakao', eventFromAndroid);
    
        return () => {
            window.removeEventListener('javascriptFunctionKakao', eventFromAndroid);
        };
        
        
    }, []);
*/  
    useEffect(() => {
        

        const countdown = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(countdown);
//            alert("입력 시간이 만료되었습니다.")
            confirmAlert({
                message: '입력 시간이 만료되었습니다',
                buttons: [{label: '확인'}]
            });
            setEnableRequestButton(true)
            setEnableConfirmButton(false)

          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => clearInterval(countdown);
    }, [minutes, seconds]);

    

    function handleAgreeClick() {

        if(nameRef.current.value.length == 0) {
//            alert("이름을 입력해 주세요!") 
            confirmAlert({
                message: '이름을 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return
        }
        if(citizenNumberRef.current.value.length < 6) {
//            alert("주민등록번호 앞자리를 입력해 주세요!")
            confirmAlert({
                message: '주민등록번호 앞자리를 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return
        }
        if(!(/^\d+$/.test(citizenNumberRef.current.value))){
 //           alert("정확한 주민등록번호 앞자리를 입력해 주세요!")
            confirmAlert({
                message: '정확한 주민등록번호 앞자리를 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return    
        }

        if(gNumberRef.current.value.length == 0) {
//            alert("주민등록번호 뒷자리 첫번째 숫자를 입력해 주세요!")
            confirmAlert({
                message: '주민등록번호 뒷자리 첫번째 숫자를 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return
        }
        if(!(/^\d+$/.test(gNumberRef.current.value))){
//            alert("정확한 주민등록번호 뒷자리 첫번째 숫자를 입력해 주세요!")
            confirmAlert({
                message: '정확한 주민등록번호 뒷자리 첫번째 숫자를 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return    
        }

        if(phoneNumberRef.current.value.length < 9) {
 //           alert("휴대폰 번호을 입력해 주세요!")
            confirmAlert({
                message: '휴대폰 번호을 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return
        }
        if(!(/^\d+$/.test(phoneNumberRef.current.value))){
//            alert("정확한 휴대폰 번호을 입력해 주세요!")
            confirmAlert({
                message: '정확한 휴대폰 번호을 입력해 주세요!',
                buttons: [{label: '확인'}]
            });
            return    
        }
        setKakaoLogin(false)
        setShowAgreeDiaglog(true)
    }
    function handleHideAgreeModal() {
        setShowAgreeDiaglog(false)
    }
      

    const navigate = useNavigate(); 

    const {globalUserData, setGlobalUserData, modeSupporter, setModeSupporter} = useContext(AppContext); 

    const nameRef = useRef("")
    const citizenNumberRef = useRef("")
    const gNumberRef = useRef("")
    const phoneNumberRef = useRef("")
    const certNumberRef = useRef("")
 
   // let _encryptMOKToken = ""
    const [_encryptMOKToken, set_encryptMOKToken] = useState("")
    const [publicKey, setPublicKey] = useState("")

    async function handleCertificate() {

 

        let agreeFlag = selectableAgree1 + selectableAgree2*2 + selectableAgree3*4

        let url =  "https://rest.besideme.life/smscert/certify_smscert.php"
        let params = {encryptMOKToken: _encryptMOKToken, publicKey: publicKey, authNumber: certNumberRef.current.value, agreeFlag: agreeFlag}


        await fetch(url, {
            method : "POST",         
            body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
        })

        .then(response=>{ 
            return response.json();
        })
        .then(data=>{ 

            if(data.resultCode != "2000")
                {
   //                 alert(data.resultMsg);
                    confirmAlert({
                        message: data.resultMsg,
                        buttons: [{label: '확인'}]
                    });

//                    clearInterval(countdown);
                    setEnableRequestButton(true)
//                    setEnableConfirmButton(false)
                    return;
                }
                
            setGlobalUserData(data.result[0])
            
            if(window.androidApp)
                window.androidApp.setPref(data.result[0].userSeqNum, data.secretKey);
        
        })
        .catch(err => {
    //        alert(err.message);
            confirmAlert({
                message: err.message,
                buttons: [{label: '확인'}]
            });
        }); 

    }
    const numberInputKeyDown = (event) => {
        const eventCode = event.code.toLowerCase();
        if (!(event.code !== null
        && (eventCode.includes("digit")
             || eventCode.includes("arrow")
             || eventCode.includes("delete")
            || eventCode.includes("home")
            || eventCode.includes("end")
            || eventCode.includes("backspace") 
            || (eventCode.includes("numpad") && eventCode.length === 7)))
        ) {
        event.preventDefault();
        }
    };

    function check_age(bdate) {
        let year = Number(bdate.substring(0,4))
        let month = Number(bdate.substring(4,6))
        let day = Number(bdate.substring(6,8))

        const d = new Date();
        let cyear= d.getFullYear();
        let cmonth = d.getMonth()+1;
        let cday = d.getDate();

//        alert(year + " " + month + " " + day + " " +cyear+ " "+cmonth+" "+cday)
        if(cyear-year > 19)
            return true;
        else if(cyear-year < 19)
            return false
        else
        {
            if(cmonth > month)
                return true;
            else if(cmonth < month)
                return false;
            else
            {
                if(cday >= day)
                    return true;
                else 
                    return false;
            }    
        } 
    }
    async function handleAgree() {

        if(kakakoLogin)
        {
            setKakaoLogin(false)
            setShowAgreeDiaglog(false)
//            window.location.href="https://rest.besideme.life/kakao_login.php?";

//            alert(navigator.userAgent)
            let url = "https://kauth.kakao.com/oauth/authorize?client_id=e4edbe92e523cb2d8c118ba83ca70427&redirect_uri=https://rest.besideme.life/kakaoauth.php&response_type=code" 
            window.location.href = url

            return
        }
        if(!(requiredAgree1 && requiredAgree2 & requiredAgree3))
        {
//            alert("필수 항목에 대한 동의를 해 주세요!")
            confirmAlert({
                message: '필수 항목에 대한 동의를 해 주세요!',
                buttons: [{label: '확인'}]
            });
            return
        }
         
//  fetch tocken & request

        let providerid = "SKT"
        if(selectedCarrier == 1)
            providerid = "SKT"
        else if(selectedCarrier == 2)
            providerid = "KT"
        else if(selectedCarrier == 3)
            providerid = "LGU"
        else if(selectedCarrier == 4)
            providerid = "SKTMVNO"
        else if(selectedCarrier == 5)
            providerid = "KTMVNO"
        else if(selectedCarrier == 6)
            providerid = "LGUMVNO"

        let gender = "1"
        let nation = "0"
        let bdate = citizenNumberRef.current.value

        let gn = parseInt(gNumberRef.current.value)

        if( (gn == 1) | (gn == 2) | (gn == 5) | (gn == 6) )
            bdate = "19"+bdate
        else if( (gn == 3) | (gn == 4) | (gn == 7) | (gn == 8) )
            bdate = "20"+bdate
        else 
            bdate = "18"+bdate

        if( gn%2 == 0)
            gender = "2"
        if( (gn >=5) && (gn <= 8) )
            nation = "1"

        let agreeFlag = selectableAgree1 + selectableAgree2*2 + selectableAgree3*4

        setShowAgreeDiaglog(false)
        if(!check_age(bdate))
        {
            confirmAlert({
                message: '만 19세 이상만 가입 가능합니다!',
                buttons: [{label: '확인'}]
            });
 
            return 
        }

        setShowAgreeDiaglog(false)
        setEnableRequestButton(false)

        let url =  "https://rest.besideme.life/smscert/request_smscert.php"
        let params = {providerid: providerid, userName: nameRef.current.value.replace(/'/g,"''"), userPhone: phoneNumberRef.current.value, userBirthday: bdate, userGender: gender, userNation: nation, agreeFlag:agreeFlag}

        await fetch(url, {
            method : "POST",         
            body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
        })
        .then(response=>{ 
            return response.json();
        })
        .then(data=>{ 

            if(data.resultCode == "100100")
            {
//                    alert(data.resultMsg);
//                    setEnableRequestButton(true)
                    setGlobalUserData(data.result[0])
                    return;
            }

            else if(data.resultCode != "2000")
            {
//                alert(data.resultMsg);
                confirmAlert({
                    message: data.resultMsg,
                    buttons: [{label: '확인'}]
                });
                setEnableRequestButton(true)
                return;
            }
        
//           alert(data.encryptMOKToken)
            set_encryptMOKToken(data.encryptMOKToken)
            setPublicKey(data.publicKey)
//            alert(data.publicKey)

            setMinutes(3)
            setSeconds(0)
            setEnableConfirmButton(true)

        })
        .catch(err => {
//            alert(err.message);
            confirmAlert({
                message: err.message,
                buttons: [{label: '확인'}]
            });
            setEnableRequestButton(true)
        }); 


    }

    function handleBackClick() { 
        navigate(-1)
    }

    function handleCheck1() {
        setRequiredAgree1(!requiredAgree1)
    }

    function handleCheck2() {
        setRequiredAgree2(!requiredAgree2)
    }
    
    function handleCheck3() {
        setRequiredAgree3(!requiredAgree3)
    }
    
    function handleCheck4() {
        setSelectableAgree1(!selectableAgree1)
    }

    function handleCheck5() {
        setSelectableAgree2(!selectableAgree2)
    }

    function handleCheck6() {
        setSelectableAgree3(!selectableAgree3)
    }

    function handleKakao() {
 
        setKakaoLogin(true)
        setShowAgreeDiaglog(true)
         
    }

    function handleSelectCarrier(carrier) {
        setSelectedCarrier(carrier)
    }

return (
    <div className='serviceSelect'>

        <div className='pageBack' style={{display: props.showBack=="true"? "block":"none"}} onClick={handleBackClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
        </div>

        <div className='inner32'>
            <h1>회원가입이 필요한 메뉴입니다.</h1>
        </div>

        <div className='inner32'>
            <div className='inputWrap'>
                <div className='label'>이름</div>
                <input className='input black' ref={nameRef} style={{}}></input>
            </div>

            <div className='inputWrap'>
                <div className='label'>주민등록번호</div>
                <div className='multi'>
                    <input className='input' ref={citizenNumberRef} maxLength={6} /* onKeyDown={numberInputKeyDown} */ placeholder='주민번호 앞자리' style={{
                        width:'136px'
                    }}></input>
                    -
                    <input className='input' ref={gNumberRef} maxLength={1} /* onKeyDown={numberInputKeyDown} */ style={{
                        width:'45px'
                    }}></input>
                    &nbsp;000000
                </div>
            </div>

            <div className='inputWrap'>
                <div className='label'>휴대폰 번호</div>
                <div className='multi gap5'>
                    <button className={'input' + ((selectedCarrier==1)?' selectedCarrier' :'')} onClick={()=>handleSelectCarrier(1)}>SKT</button>
                    <button className={'input' + ((selectedCarrier==2)?' selectedCarrier' :'')} onClick={()=>handleSelectCarrier(2)}>KT</button>
                    <button className={'input' + ((selectedCarrier==3)?' selectedCarrier' :'')} onClick={()=>handleSelectCarrier(3)}>LG U+</button> 
                </div>

                <div className='multi gap5' style={{marginTop:"2px"}}>
                    <button className={'input' + ((selectedCarrier==4)?' selectedCarrier' :'')} onClick={()=>handleSelectCarrier(4)}>SKT 알뜰폰</button>
                    <button className={'input' + ((selectedCarrier==5)?' selectedCarrier' :'')} onClick={()=>handleSelectCarrier(5)}>KT 알뜰폰</button>
                    <button className={'input' + ((selectedCarrier==6)?' selectedCarrier' :'')} onClick={()=>handleSelectCarrier(6)}>LG U+ 알뜰폰</button>
                </div>
                <div className="multi" style={{
                    marginTop:'5px',
                    gap:0,
                }}>
                    <input className='input' ref={phoneNumberRef} maxLength={11} /* onKeyDown={numberInputKeyDown} */ style={{}}></input>
                    <button disabled={!enableRequestButton} onClick={handleAgreeClick} className={'goldBtn ' + (!enableRequestButton?'disabled':'')}  style={{
                        padding:'0 6px',
                        flex:'0 0 auto',
                        width:'calc((100% - 15px) / 4)',
                        height:'45px',
                        marginLeft:'5px',
                    }}>인증요청</button>
                </div>
            </div>

            {/* 필요 없는 화면에선 지워주세요 */}
            <div className='inputWrap'>
                <div className='label' style={{display:"inline-block"}}>인증번호 <div hidden={!enableConfirmButton} style={{fontSize:"12px", display:"inline-block"}}>(남은 시간: {minutes}:{seconds < 10 ? `0${seconds}` : seconds})</div></div>
                <div className="multi" style={{
                    marginTop:'5px',
                    gap:0,
                }}>
                    <input className='input' ref={certNumberRef}  placeholder='인증번호를 입력하세요' /* onKeyDown={numberInputKeyDown} */ style={{}}></input>
                    <button disabled={!enableConfirmButton} onClick={handleCertificate} className={'goldBtn ' + (!enableConfirmButton?'disabled':'')} style={{
                        padding:'0 6px',
                        flex:'0 0 auto',
                        width:'calc((100% - 15px) / 4)',
                        height:'45px',
                        marginLeft:'5px'
                    }}>확인</button>
                </div>
                
            </div>
            <div hidden={true}>
            <div className='tit-sm' style={{
                marginTop:'30px',
                marginBottom:'30px',
                textAlign:'center'
            }}>
                     또는
            </div>

            <button onClick={handleKakao} className='kakaoBtn' style={{
                marginTop:'24px'
            }}>
                <div className='icon'></div>
                카카오로 가입하기
            </button>
            </div>
            {/*
            <div className='ctaWrap bottomCta' style={{bottom:'25px'}}>
                <button className='cta'>본인인증완료</button></div>
            */}
        </div>

        <ReactModal 
            ariaHideApp={false}
            isOpen={showAgreeDialog} 
            onRequestClose={handleHideAgreeModal} 
            style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                  position: 'absolute',
                  top: '200px',
                  left: '40px',
                  right: '40px',
                  bottom: '40px',
                  border: '1px solid #ccc',
                  background: '#fff',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '4px',
                  outline: 'none',
                  padding: '20px'
                }
              }}>
            <div className='bottomModal' style={{
                height:'auto',
                paddingBottom:'100px'
            }}>
                <div className='closeBtn' onClick={handleHideAgreeModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div className='tit-sm'>
                    서비스 이용을 위해<br/>
                    필수 항목에 동의해 주세요
                </div>

                <ul className='inner needAgree'>
                    <li>
                        <label>
                            <input type="checkbox" checked={requiredAgree1} onChange={handleCheck1}></input>
                        <div className='checkIcon'></div>
                        [필수] 만 19세 이상입니다
                        </label>
                    </li>
                    <li>
                        <label>
                        <input type="checkbox" checked={requiredAgree2} onChange={handleCheck2}></input>
                        <div className='checkIcon'></div>
                        [필수] 서비스 이용 약관
                        </label>
                        <a href="https://upbeat-texture-d71.notion.site/13cc0f44ef6a809c85aee4ae51fe137a"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></a>
                    </li>
                    <li>
                        <label>
                        <input type="checkbox" checked={requiredAgree3} onChange={handleCheck3}></input>
                        <div className='checkIcon'></div>
                        [필수] 개인정보 수집, 이용동의
                        </label>
                        <a href="https://upbeat-texture-d71.notion.site/13cc0f44ef6a80d38dc5fc9b39c62a80"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></a>
                    </li>
                    <li>
                        <label>
                        <input type="checkbox" checked={selectableAgree1} onChange={handleCheck4}></input>
                        <div className='checkIcon'></div>
                        [선택] 마케팅 활용을 위한 개인정보 수집, 이용동의
                        </label>
                        <a href="https://upbeat-texture-d71.notion.site/13cc0f44ef6a80d096e7e09649d0b886?pvs=74"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></a>
                    </li>
                    <li>
                        <label>
                        <input type="checkbox" checked={selectableAgree2} onChange={handleCheck5}></input>
                        <div className='checkIcon'></div>
                        [선택] 광고성 정보 수신 동의
                        </label>
                        <a href="https://upbeat-texture-d71.notion.site/164c0f44ef6a80158261d0c798395577"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></a>
                    </li>
                    <li>
                        <label>
                        <input type="checkbox" checked={selectableAgree3} onChange={handleCheck6}></input>
                        <div className='checkIcon'></div>
                        [선택] 앱푸시 알림 승인
                        </label>
                        
                    </li>
                    {/*<button className='cta' onClick={''}>전체 동의 후 인증하기</button>*/}
                </ul>

                <button disabled={!(requiredAgree1 && requiredAgree2 & requiredAgree3)} className={'cta ' + (!(requiredAgree1 && requiredAgree2 & requiredAgree3)?'disabled':'')} onClick={handleAgree}  style={{
                marginBottom:'20px'
            }}>{kakakoLogin?"동의 후 카카오로 가입하기":"동의 후 인증 요청하기"}</button>
          </div>
        
        </ReactModal>

    </div>
  )
};

export default NeedLogin;
