import React, { useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App';
import NoticeList from './NoticeList';
 
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../css/confirmalert.css'
 
export default function NoticeMain() {

  const navigate = useNavigate(); 
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 
 

  const location = useLocation()
  const noticeid = location. state.noticeID
  const [noticetitle, setNoticeTitle] = useState(location.state.noticeTitle)
  const [noticemain, setNoticeMain] = useState(location.state.noticeMain) 
  const showlistbutton = location.state.showListButton
  
  
  const initRead = async() => {
  
    let url = "https://rest.besideme.life/rest/v1/noticelist?noticeID="+noticeid;

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
        {
     //     alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
        setNoticeTitle(data.result[0].noticeTitle)
        setNoticeMain(data.result[0].noticeMain)
    })
    .catch(err => {
   //     alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
  }
  useEffect(() => {initRead()},[noticeid]);
 

  function handleBackClick() {
    navigate(-1)
  }
   
  function handleBackToList() {
    navigate("/Mypage/NoticeList", { replace: false, state : {showListButton: false}})
  }

return (
    <div className='noticeMain'>

      
      <div className="subpageHeader">
        <div className="back" onClick={handleBackClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </div>
        <h2>{noticetitle}</h2>
      </div>

      <div className='inner'>
        <div className='contents'>
          <p>
            {noticemain}
          </p>
        </div>
        <div className='backBtn' hidden={!showlistbutton}><button onClick={handleBackToList}>목록</button></div>
      </div>


    </div>
  )
};