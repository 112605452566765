import React, {useState, useEffect, useRef, useContext} from 'react';
import { useNavigate, useLocation} from "react-router-dom"; 
import { AppContext } from '../../App';
import ReactModal from 'react-modal';

import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../css/confirmalert.css'

export default function SupporterInfo() {
  
  const navigate = useNavigate();
    
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 
  const [desc, setDesc] = useState()
  const [descDetail, setDescDetail] = useState()
  const [styleFlag, setStyleFlag] = useState([true, true, true, true])
  const [haveCar, setHaveCar] = useState(false)
  const [activeRegions, setActiveRegions] = useState([])

  const [showRegionDialog, setShowRegionDiaglog] = useState(false)
  const [regionSearchList, setRegionSeachList] = useState([])
  let ndate = new Date()
  const [photoFileName, setPhotoFileName] = useState("https://buddypics.s3.ap-northeast-2.amazonaws.com/"+globalUserData.userSeqNum+".jpg?"+ndate)

  const keywordRef = useRef("")

  function handleBackClick() {  
    navigate(-1) 
  }

  const initRead = async() => {

    setPhotoFileName("https://buddypics.s3.ap-northeast-2.amazonaws.com/"+globalUserData.userSeqNum+".jpg?"+ndate) 
    if(globalUserData.userSeqNum == 0)
      return
    let url =  "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/activeregionlist"

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
//        alert(data.message);
          confirmAlert({
              message: data.message,
              buttons: [{label: '확인'}]
          });
        return;
      }
      setActiveRegions(data.result)
   
    })
    .catch(err => {
//        alert(err.message);
          confirmAlert({
              message: err.message,
              buttons: [{label: '확인'}]
          });        
    }); 
  }
  useEffect(() => {
    
    initRead()

    const eventFromAndroid = async (event) => {
//      window.location.href = "https://rest.besideme.life/refresh.php"
      let ndate = new Date()
      setPhotoFileName("https://buddypics.s3.ap-northeast-2.amazonaws.com/"+globalUserData.userSeqNum+".jpg?"+ndate) 
    }

    window.addEventListener('javascriptFunction', eventFromAndroid);
 
    return () => {
        window.removeEventListener('javascriptFunction', eventFromAndroid);
    };
  },[globalUserData.userSeqNum]);


  async function handleSave() {

    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum
    let styleVal = (styleFlag[0]?1:0) +  (styleFlag[1]?2:0) + (styleFlag[2]?4:0) + (styleFlag[3]?8:0)
    let haveCarVal = haveCar?1:0
    let params = {supStatus : 2, supDesc : desc.replace(/'/g,"''"), supDescDetail: descDetail.replace(/'/g,"''"), supStyle:styleVal, supHaveCar: haveCarVal }


    await fetch(url, {     
    method : "PUT",         
    body: JSON.stringify(params)   
    })
    .then(response=>{  
    return response.json();
    })
    .then(data=>{   
      if(data.code == "error")
        {
//          alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
      let globalUserData_ = {...globalUserData}
      globalUserData_.supStatus = 2
      globalUserData_.supDesc = desc
      globalUserData_.supDescDetail = descDetail
      globalUserData_.supStyle = styleVal
      globalUserData_.supHaveCar = haveCarVal
      setGlobalUserData(globalUserData_) 

      confirmAlert({
        message: '저장되었습니다.',
        buttons: [{label: '확인'}]
      });

      handleBackClick()
      
    })
    .catch(err => {
  //      alert(err.message);
        confirmAlert({
            message: err.message,
            buttons: [{label: '확인'}]
        });
    }); 

  }

  useEffect(() => {
    setDesc(globalUserData.supDesc)
    setDescDetail(globalUserData.supDescDetail)
    if(globalUserData.supHaveCar > 0)
      setHaveCar(true)
    else
      setHaveCar(false)

    let styleFlag_ = [...styleFlag]    
    if((globalUserData.supStyle & 1) > 0)
      styleFlag_[0] = true
    else
      styleFlag_[0] = false
    
    if((globalUserData.supStyle & 2) > 0)
      styleFlag_[1] = true
    else
      styleFlag_[1] = false
    
    if((globalUserData.supStyle & 4) > 0)
      styleFlag_[2] = true
    else
      styleFlag_[2] = false   
    
    if((globalUserData.supStyle & 8) > 0)
      styleFlag_[3] = true
    else
      styleFlag_[3] = false     
    setStyleFlag(styleFlag_)
  }, [])
 

  function handleSet(index) {
    let styleFlag_ = [...styleFlag]    
    styleFlag_[index] = true
    setStyleFlag(styleFlag_)
  }


  function handleReset(index) {

    let styleFlag_ = [...styleFlag]    
    styleFlag_[index] = false
    setStyleFlag(styleFlag_)
  }

  function handleHaveCar(v) {
    setHaveCar(v)
  }

  async function addRegion(region, regionID) {
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/activeregionlist"
    let params = {regionID: regionID}

    if(activeRegions.length >= 10) 
    {
      confirmAlert({
          message: '서포트 가능 지역은 최대 10개까지만 지정할 수 있습니다.',
          buttons: [{label: '확인'}]
      });
      return;
    }

    await fetch(url, {
      method : "POST",         
      body: JSON.stringify(params)    
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
      {
//        alert(data.message);
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
      });
        return;
      } 
      initRead()

    })
    .catch(err => {
//        alert(err.message);
        confirmAlert({
            message: err.message,
            buttons: [{label: '확인'}]
        });
    }); 
  }

  async function handleRegionSearch() {

    const keyword = keywordRef.current.value
    if(keyword.length <2)
    {
//      alert("2글자 이상 검색해 주세요!")
      confirmAlert({
        message: "2글자 이상 검색해 주세요!",
        buttons: [{label: '확인'}]
    });
      return
    }
    let url = "https://rest.besideme.life/rest/v1/regionlist?keyword="+keyword;

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
//        alert(data.message);
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
      });
        return;
      }
      setRegionSeachList(data.result)
      if(data.result.length == 0)
        confirmAlert({
          message: "검색 결과가 없습니다.",
          buttons: [{label: '확인'}]
      });

    })
    .catch(err => {
//        alert(err.message);
          confirmAlert({
              message: err.message,
              buttons: [{label: '확인'}]
          });
    }); 
  }

  async function doDelete(regionID) {
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/activeregion/"+regionID;
    let params = {}

    await fetch(url, {
      method : "DELETE",       
      body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{    
      if(data.code == "error")
        {
//          alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
      initRead()
    })
    .catch(err => {
//        alert(err.message);
          confirmAlert({
              message: err.message,
              buttons: [{label: '확인'}]
          });
    }); 
  }

  async function deleteRegion(region, regionID) {  

    confirmAlert({ 
      message: region + ' : 서포터 가능 지역에서 삭제하시겠습니까?',
      buttons: [
        { label: '아니오' },
        {
          label: '예',
          onClick: () => doDelete(regionID)
        } 
      ]
    });

//    if(window.confirm("'"+region + "' : 서포터 가능 지역에서 삭제하시겠습니까?") == true) 
//      {
//        doDelete()
//      }
  }

  function handleRegionClick() {
    const empty = new Array()
    setRegionSeachList(empty)
    setShowRegionDiaglog(true)
  }
  function handleHideRegionModal() {
    const empty = new Array()
    setRegionSeachList(empty)
    setShowRegionDiaglog(false)
  }

  function handleUploadPhoto() {
//    alert("준비중입니다.")
  }

  const handleSearchKeyDown = (e) => {
    if(e.key === "Enter") {
      handleRegionSearch();
    }
  }
    return (
      <>
        <div className='serviceSelect inner'>
            <div className='pageBack' onClick={handleBackClick}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
              <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
              </svg>
            </div>

            <div className='inner' style={{marginBottom:'50px'}}>
              <div className='profileWrap'>
              <a href={"https://rest.besideme.life/uploadphotobridge.php?userseqnum="+globalUserData.userSeqNum}>
               <div className={'profile ' + (globalUserData.isCertified==0?'nobadge':'badge')}>
                 <img src={photoFileName} onError={(e) => e.target.src = 'https://buddypics.s3.ap-northeast-2.amazonaws.com/profile.png'} onClick={handleUploadPhoto}></img>
                  <svg className='append' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="9" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 13V17H11V13H7V11H11V7H13V11H17V13H13Z" fill="#222222"/>
                  </svg>
               </div>
              </a>
                <div className='userName'>{globalUserData.userName}</div>
              </div>
            </div>

            <div className='inputWrapTit'>자기 소개 (간단히)</div>
            <textarea className='borderTextArea' style={{height:'100px', marginBottom:'40px'}} value={desc}  onChange={(event)=>setDesc(event.target.value)} 
            rows="2" placeholder="ex. 한국대학교 조소과 휴학생이고 외할머니와 함께 살아서 어르신과 소통이 잘 되고, 어르신을 잘 따릅니다. (최대 50자)"></textarea>

            <div className='inputWrapTit' style={{marginBottom:'12px'}}>서포터 가능 지역</div>
            <div className='myLocationItemWrap'>
              {
                activeRegions.map((region, index) => (
                    <div className='myLocationItem'>{region.region}</div>
                  ))
              }
            </div>
            <button className='goldBtn' style={{marginBottom:'40px', marginTop:'12px'}} onClick={handleRegionClick}>위치 설정하기</button>

            <ReactModal isOpen={showRegionDialog} onRequestClose={handleHideRegionModal} ariaHideApp={false}>
              <div className='pageBack' onClick={() => setShowRegionDiaglog(false)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
              </div>
                <div className='inner'>
                  <h1>서포트 가능 지역 (현재는 서울 지역만 가능합니다.) </h1>

                  <div className='myLocation'>
                    {
                    activeRegions.map((region, index) => (
                        <div className='item' >{region.region} &nbsp;              
                        (<svg onClick={() => deleteRegion(region.region, region.regionID)} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="red" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="red" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>)
                        </div>
                      ))
                    }
                  </div>

                  <div className='searchMap'>
                    <svg className='location' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.398 19.804L12.8585 20.6917L12.8585 20.6917L12.398 19.804ZM11.602 19.804L11.1415 20.6917L11.1415 20.6917L11.602 19.804ZM18 11C18 13.1458 16.9079 14.9159 15.545 16.2906C14.183 17.6644 12.6342 18.555 11.9376 18.9163L12.8585 20.6917C13.6448 20.2838 15.397 19.2805 16.9653 17.6987C18.5326 16.1178 20 13.8706 20 11H18ZM12 5C15.3137 5 18 7.68629 18 11H20C20 6.58172 16.4183 3 12 3V5ZM6 11C6 7.68629 8.68629 5 12 5V3C7.58172 3 4 6.58172 4 11H6ZM12.0624 18.9163C11.3658 18.555 9.81702 17.6644 8.45503 16.2906C7.09211 14.9159 6 13.1458 6 11H4C4 13.8706 5.46741 16.1178 7.03474 17.6987C8.60299 19.2805 10.3552 20.2838 11.1415 20.6917L12.0624 18.9163ZM11.9376 18.9163C11.9514 18.9091 11.9733 18.9023 12 18.9023C12.0267 18.9023 12.0486 18.9091 12.0624 18.9163L11.1415 20.6917C11.6831 20.9726 12.3169 20.9726 12.8585 20.6917L11.9376 18.9163ZM14 11C14 12.1046 13.1046 13 12 13V15C14.2091 15 16 13.2091 16 11H14ZM12 9C13.1046 9 14 9.89543 14 11H16C16 8.79086 14.2091 7 12 7V9ZM10 11C10 9.89543 10.8954 9 12 9V7C9.79086 7 8 8.79086 8 11H10ZM12 13C10.8954 13 10 12.1046 10 11H8C8 13.2091 9.79086 15 12 15V13Z" fill="#33363F"/>
                    </svg>
                    <input ref={keywordRef} type="text" placeholder='구 이름으로 검색(ex. 송파구)' onKeyDown={(e) => handleSearchKeyDown(e)}></input>
                    <button onClick={handleRegionSearch}>검색</button>
                  </div>
                  <div className='locationList' style={{marginBottom:"22px"}}>
                    {
                    regionSearchList.map((region, index) => (
                        <div onClick={() => addRegion(region.region, region.regionID)}>{region.region}</div>
                      ))
                    }
                  </div>
                </div>
                <button className='cta' onClick={handleHideRegionModal}>확인</button>
            </ReactModal>


            <div className='inputWrapTit'>자기 소개 (자세히)</div>
            <textarea className='borderTextArea' style={{height:'300px', marginBottom:'34px'}} value={descDetail}  onChange={(event)=>setDescDetail(event.target.value)} 
            rows="2" placeholder="ex. 한국대학교 조소과 휴학생이고 외할머니와 함께 살아서 어르신과 소통이 잘 되고, 어르신을 잘 따릅니다. (최대 150자)"></textarea>

            <div className='inputWrapTit' style={{marginBottom:'13px'}}>성격 및 성향</div>
            
            <div className='radio2' style={{marginBottom:'34px'}}>
              <div className={styleFlag[0]?"item fill":"item"} onClick={() => handleSet(0)}> 내성적인</div>
              <div className={styleFlag[0]?"item":"item fill"} onClick={() => handleReset(0)}>외향적인</div>
              <div className={styleFlag[1]?"item fill":"item"} onClick={() => handleSet(1)}> 친절한</div>
              <div className={styleFlag[1]?"item":"item fill"} onClick={() => handleReset(1)}>단호한</div>
              <div className={styleFlag[2]?"item fill":"item"} onClick={() => handleSet(2)}> 꼼꼼한</div>
              <div className={styleFlag[2]?"item":"item fill"} onClick={() => handleReset(2)}>대범한</div>
              <div className={styleFlag[3]?"item fill":"item"} onClick={() => handleSet(3)}> 차분한</div>
              <div className={styleFlag[3]?"item":"item fill"} onClick={() => handleReset(3)}>활기찬</div>
            </div>

            
            <div className='inputWrapTit' style={{marginBottom:'13px'}}>자가 차량 보유</div>
            <div className='radio2' style={{marginBottom:'60px'}}>
              <div className={haveCar?"item fill":"item"} onClick={() => handleHaveCar(true)}> 보유</div> 
              <div className={haveCar?"item":"item fill"} onClick={() => handleHaveCar(false)}>미보유</div>
            </div>
        </div>
        <div className='goldBox'>
          <button className='cta' onClick={handleSave}>저장</button>
        </div>
      </>
    )

};
 