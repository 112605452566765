import React, {useState, useEffect, useContext, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import { AppContext } from '../../App';
  
import { confirmAlert } from 'react-confirm-alert'; // Import 
import '../../css/confirmalert.css'

export default function ServiceDetail() {
  
  const navigate = useNavigate();
  
  //const { phoneNumber, uniqueID, accessKey, seqNumber, setPhoneNumber, setUniqueID, setAccessKey, setSeqNumber } = useContext(AppContext); 
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const [petExist, setPetExist] = useState(false)
  const [cctvExist, setCctvExist] = useState(false)
  const [radioChecked, setRadioChecked] = useState([true, false, false])


  const enterRef = useRef("")
  const detailRef = useRef("")
  const additionalRef = useRef("") 


  const location = useLocation();	

  const categorySeqNum = location.state.categoryseqnum;	
  const categorySubSeq = location.state.categorysubseq;	
  const categorySubName = location.state.categorysubname;	
  const serviceDate = location.state.servicedate
  const serviceTime = location.state.servicetime
  const serviceDuration = location.state.serviceduration 
  const pageData = location.state.pagedata
   
  function handleBackClick() {  
    navigate(-1) 
  }
    
  function handleRadioChange(radioIndex) 
  {  
    let radioChecked_ = new Array(3).fill(false)
    radioChecked_[radioIndex] = true
    setRadioChecked(radioChecked_)

  }

  async function handleNextClick() {  
    let radioIndex = 0 
    if(radioChecked[1])
      radioIndex = 1
    else if(radioChecked[2])
      radioIndex = 2


    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/servicelist";
    let params = {categorySeqNum: (categorySeqNum), categorySubSeq: (categorySubSeq), serviceTitle: (categorySubName), serviceDate: (serviceDate), serviceTime: (serviceTime),
                  serviceDuration: (serviceDuration), addr: (pageData.addr.replace(/'/g,"''")),addrDetail: (pageData.addrDetail.replace(/'/g,"''")), 
                  entranceIndex: (radioIndex), petExist: (petExist?1:0), cctvExist: (cctvExist?1:0),
                  entranceDetail: (enterRef.current.value.replace(/'/g,"''")), helpDetail: (detailRef.current.value.replace(/'/g,"''")),additionalDetail: (additionalRef.current.value.replace(/'/g,"''")),
                  regionID: (pageData.regionID)}

    await fetch(url, {
      method : "POST",         
      body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
        {
    //      alert(data.message);
          confirmAlert({
            message: data.message,
            buttons: [{label: '확인'}]
        });
          return;
        }
      navigate("/Home/ServiceRequestComplete", { replace: true, state: { categorysubname: categorySubName, servicedate: serviceDate, servicetime: serviceTime, serviceduration:serviceDuration }} )
    })
    .catch(err => {
  //      alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
 
  }
 

    return (
        <div className='serviceDetail'>
            <div className="subpageHeader">
              <div className="back" onClick={handleBackClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
              </div>
              <h2>
              추가 정보를 알려주세요
              </h2>
            </div>
            <div className='inner'>
              <div className='radioList'>
                <div className='tit'>출입방법</div>
                <label>
                  <div className='label'>오시면 초인종을 눌러주세요</div>
                  <div className='icon'>
                    <input checked={radioChecked[0]} type="radio" name="radio" onChange={() => handleRadioChange(0)}></input>
                    <svg className='on' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#45D88E" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#45D88E"/></svg>
                    <svg className='off' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F1EEE1" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#F1EEE1"/></svg>
                  </div>
                </label>
                <label>
                  <div className='label'>오시면 전화주세요</div>
                  <div className='icon'>
                    <input checked={radioChecked[1]} type="radio" name="radio" onChange={() => handleRadioChange(1)}></input>
                    <svg className='on' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#45D88E" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#45D88E"/></svg>
                    <svg className='off' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F1EEE1" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#F1EEE1"/></svg>
                  </div>
                </label>
                <label>
                  <div className='label'>직접 입력</div>
                  <div className='icon'>
                    <input  checked={radioChecked[2]} type="radio" name="radio" onChange={() => handleRadioChange(2)}></input>
                    <svg className='on' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#45D88E" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#45D88E"/></svg>
                    <svg className='off' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F1EEE1" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#F1EEE1"/></svg>
                  </div>
                </label>
                <textarea className='borderTextArea' ref={enterRef} style={{display: radioChecked[2]? "block":"none"}}rows="3" placeholder="예)공동현관 비밀번호 0000입니다. 들어오셔서 집앞에서 초인종을 눌러주세요."></textarea>  
              </div>

              <div className='tit'>
                반려동물
              </div>
              <div className='toggleBtnWrap'>
                <button className={petExist ? "on":""} onClick={() => setPetExist(true)}>있음</button>  
                <button className={petExist ? "":"on"} onClick={() => setPetExist(false)}>없음</button>
              </div>

              <div className='tit'>
                CCTV
              </div>
              <div className='toggleBtnWrap'>
                <button className={cctvExist ? "on":""} onClick={() => setCctvExist(true)}>있음</button> 
                <button className={cctvExist ? "":"on"} onClick={() => setCctvExist(false)}>없음</button>
              </div>

              <div className='tit'>
                도움이 필요한 내용을 상세하게 알려주세요
              </div>
              <textarea className='borderTextArea' ref={detailRef} rows="5" placeholder="예)암검사 정기 검진입니다. 9시 예약으로 검진 이동 및 검진 후 처방전 수령, 추가 예약 일자 확인 등 도움이 필요합니다."></textarea>

              <div className='tit'>
                추가적으로 안내해 주실 내용을<br/> 
                기재해 주세요
              </div>
              <textarea className='borderTextArea' ref={additionalRef} placeholder="예)없음"></textarea> 
            </div>
            
            <div className='ctaWrap bottomCta'>
              <button className='cta' onClick={handleNextClick}>요청하기</button>
            </div>
        </div>
      )

};
 