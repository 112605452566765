import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import { AppContext } from '../../App';
import Calendar from 'react-calendar';
//import "../../css/calendarcustom.css"
import 'react-calendar/dist/Calendar.css';
import {format} from 'date-fns';
import NeedLogin from '../NeedLogin'; 

import { confirmAlert } from 'react-confirm-alert'; // Import 
import '../../css/confirmalert.css'

export function AddressInfo({pageData, addrIndex}) {

  const navigate = useNavigate(); 
    
  function handleAddressList() {
    navigate("/Mypage/AddressList", { replace: false, state : {addrIndex: (addrIndex)}})

  }

  return (
    <div className='selectPlace'>
      <div className='radioCard'>
        <div className='icon'></div>
        <input type="radio" name="radio" checked></input>
        <div className='tit'>{pageData.addrName}</div>
        <div className='addr'>
          {pageData.addr}<br/>
          {pageData.addrDetail}
        </div>
      </div>
      <button className='addPlace' onClick={handleAddressList}>방문 장소 관리</button>
    </div>
  ) 
};

export function TimeList(props) {
  
  const handleTimeSelectClick = (time) => {  
    props.callback(time) 
  }
  return (
    <div className='pickTime'>
        <div onClick={() => handleTimeSelectClick("8:00")}>8:00</div>
        <div /*className='ac'*/ onClick={() => handleTimeSelectClick("9:00")}>9:00</div>
        <div onClick={() => handleTimeSelectClick("10:00")}>10:00</div>
        <div onClick={() => handleTimeSelectClick("11:00")}>11:00</div>
        <div onClick={() => handleTimeSelectClick("12:00")}>12:00</div>
        <div onClick={() => handleTimeSelectClick("13:00")}>13:00</div>
        <div onClick={() => handleTimeSelectClick("14:00")}>14:00</div>
        <div onClick={() => handleTimeSelectClick("15:00")}>15:00</div>
        <div onClick={() => handleTimeSelectClick("16:00")}>16:00</div>
    </div>
  )
}


export function DurationList(props) {

  const handleDurationSelectClick = (duration) => {  
    props.callback(duration) 
  }
  return (
    <div>
      <div className='pickTime'>
        <div onClick={() => handleDurationSelectClick("1시간")}>1시간</div>
        <div /*className='ac'*/ onClick={() => handleDurationSelectClick("2시간")}>2시간</div>
        <div onClick={() => handleDurationSelectClick("3시간")}>3시간</div>
        <div onClick={() => handleDurationSelectClick("4시간")}>4시간</div>
        <div onClick={() => handleDurationSelectClick("5시간")}>5시간</div>
        <div onClick={() => handleDurationSelectClick("6시간")}>6시간</div> 
        <div onClick={() => handleDurationSelectClick("12시간")}>12시간</div>
        <div onClick={() => handleDurationSelectClick("24시간")}>24시간</div>
        <div onClick={() => handleDurationSelectClick("36시간")}>36시간</div>
      </div>
    </div>
  )
}

export default function ServicetDateTime() {
  
  const navigate = useNavigate();
  
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const [serviceDate, setServiceDate] = useState("")
  const [serviceTime, setServiceTime] = useState("")
  const [serviceDuration, setServiceDuration] = useState("")

  const [showCalendar, setShowCalendar] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [showDuration, setShowDuration] = useState(false)

  const [nextEnabled, setNextEnabled] = useState(false)

  const [steps, setSteps] = useState([false, true, true, true])

  const [calendarValue, setCalendarValue] = useState(new Date())

  const [pageData, setPageData] = useState({userName : "", userPhoneNum : "", addrName : "", addr : "", addrDetail : ""})
  const [addrIndex, setAddrIndex] = useState(-1)

  const [addressSelected, setAddressSelected] = useState(false)

  const location = useLocation();	

  const categorySeqNum = location.state.categoryseqnum;	
  const categorySubSeq = location.state.categorysubseq;	
  const categorySubName = location.state.categorysubname;	


  function handleBackClick() {  
    navigate(-1) 
  }
   
  function handleDateSelectClick() {

    setShowCalendar(true);
    setShowTime(false)
    setShowDuration(false)
  }

  function handleCalendarChange(date) {

//    setCalendarValue(calendarValue)

//    setDateLabel(calendarValue.value) 
    setServiceDate(format(date, 'yyyy년 MM월 dd일'))

    setShowCalendar(false)

    let steps_ = [...steps]
    steps_[1] = false
    setSteps(steps_)
  }
 
  function handleTimeSelectClick() {

    setShowCalendar(false);
    setShowTime(true)
    setShowDuration(false)
 
  }

  function handleTimeChange(time) { 
    setShowTime(false)
    setServiceTime(time)

    let steps_ = [...steps]
    steps_[2] = false
    setSteps(steps_)
  }

  function handleDurationSelectClick() {

    setShowCalendar(false);
    setShowTime(false)
    setShowDuration(true)
 
  }

  function handleDurationChange(duration) {
    setShowDuration(false)
    setServiceDuration(duration)

    let steps_ = [...steps]
    steps_[3] = false
    setSteps(steps_)

  }

  function handleNextClick() {
    navigate("/Home/ServiceDetail", { replace: false, state: {pagedata:pageData, categoryseqnum:categorySeqNum, categorysubseq:categorySubSeq, categorysubname:categorySubName, servicedate: serviceDate, servicetime: serviceTime, serviceduration:serviceDuration }}) 
  }
 
  const initRead = async() => {

    if(globalUserData.userSeqNum <= 0)
      return
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum;


    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
//        alert(data.message);
        confirmAlert({
          message: data.message,
          buttons: [{label: '확인'}]
      });
        return;
      }

      setAddrIndex(data.result.userAddrIndex)
      setPageData(data.result)

      if(addrIndex == -1) { 

        setNextEnabled(false)
      }
      else {
        setNextEnabled(true)
      }  
    })
    .catch(err => {
//        alert(err.message);
        confirmAlert({
          message: err.message,
          buttons: [{label: '확인'}]
      });
    }); 
  }
  useEffect(() => {initRead()},[globalUserData.userSeqNum, addrIndex]);

  var mindate = new Date();
  mindate.setDate(mindate.getDate() + 1);

  function handleNextClicked() {
    setAddressSelected(true)
  }  
  if(globalUserData.userSeqNum <= 0)
    return (
      <div>
        <NeedLogin showBack="true"/>
      </div>
  ) 
  else if(!addressSelected)
    return (
      <div>
          <div className="subpageHeader">
            <div className="back" onClick={handleBackClick}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
            </div>
            <h2>방문 장소를 선택해 주세요</h2>
          </div>

          <div className='inner'>
            <AddressInfo pageData = {pageData} addrIndex={addrIndex}/> 
            <div className="ctaWrap bottomCta">
              <button className={'cta ' + (!nextEnabled?'disabled':'')}disabled={!nextEnabled} onClick={handleNextClicked}>다음</button>
            </div>
          </div>
          
      </div>
  )
  else 
    return (
        <div className='serviceDate'>

            <div className="subpageHeader">
              <div className="back" onClick={handleBackClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
              </div>
              <h2>날짜/시간을 선택해 주세요</h2>
            </div>

            <div className='inner'>
              <table className='table2'>
                <tr>
                  <th>요청 일자</th>
                  <td>{serviceDate}</td>
                  <td>
                    <button className={'btn01 ' + (steps[0]?'disabled':'')} disabled={steps[0]} onClick={handleDateSelectClick}>선택</button>
                  </td>
                </tr>
              </table>
            </div>
            <div className='calendar' style={{display:showCalendar?"block":"none"}}>
              <Calendar 
                minDetail="month" 
                next2Label={null} 
                prev2Label={null} 
                maxDetail="month" 
                showNavigation="false" 
                locale="ko" 
                minDate={mindate}
                onChange={handleCalendarChange} 
                value={calendarValue}
              />
            </div>

            <div className='inner'>
              <table className='table2'>
                <tr>
                  <th>요청 시간</th>
                  <td>{serviceTime}</td>
                  <td>
                    <button className={'btn01 ' + (steps[1]?'disabled':'')} disabled={steps[1]} onClick={handleTimeSelectClick}>선택</button>
                  </td>
                </tr>
              </table>
            </div>

            <div style={{display:showTime?"block":"none"}}>
              <TimeList callback={handleTimeChange}/>
            </div>

            <div className='inner'>
              <table className='table2'>
                <tr>
                  <th>소요 시간</th>
                  <td>{serviceDuration}</td>
                  <td>
                    <button className={'btn01 ' + (steps[2]?'disabled':'')} disabled={steps[2]} onClick={handleDurationSelectClick}>선택</button>
                  </td>
                </tr>
              </table>
            </div>
            <div style={{display:showDuration?"block":"none"}}>
              <DurationList callback={handleDurationChange}/>
            </div>
            <div className='ctaWrap bottomCta'>
              <button className={'cta ' + (steps[3]?'disabled':'')} disabled={steps[3]} onClick={handleNextClick}>다음</button>
            </div>
        </div>
      )


};
 